html {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 100%;
  background: #f1f3f5;
  color: #343a40;
}

img {
  max-width: 100%;
}

a {
  color: currentColor;
  text-decoration: none;
}

.card {
  margin: 6.4vw;
  padding: 6.4vw;
  background: #fff;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  border-radius: 4px;
  box-shadow: 0 0 6px -2px #343a40;
}

.profile {
  flex: none;
  text-align: center;
}

.name {
  margin: 0;
  font-size: 24px;
}

.content, .footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px #ced4da;
}

.content > :first-child {
  margin-top: 0;
}

.content > :last-child {
  margin-bottom: 0;
}

.content h2 {
  margin: 0.5em 0;
}

.content p {
  margin: 0.5em 0;
}

.tags {
  margin: 0.5em 0;
  padding: 0;
  list-style-type: none;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags > li {
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 4px 8px;
  background: #f1f3f5;
  border-radius: 4px;
}

.links li {
  margin: 0.5em 0;
}

.footer {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .card {
    align-items: center;
    margin: 0;
    padding: 32px;
    width: 80%;
    max-width: 960px;
  }
}
/*# sourceMappingURL=style.ce108f31.css.map */
